export default {
	colors: {
		text: "#000",
		background: "#fff",
		primary: "#3b5bdb",
		primarys: [
			"#edf2ff",
			"#dbe4ff",
			"#bac8ff",
			"#91a7ff",
			"#748ffc",
			"#5c7cfa",
			"#4c6ef5",
			"#4263eb",
			"#3b5bdb",
			"#364fc7",
		],
		white: "#FFFFFF",
		black: "#000000",
		grays: [
			"#f8f9fa",
			"#f1f3f5",
			"#e9ecef",
			"#dee2e6",
			"#ced4da",
			"#adb5bd",
			"#868e96",
			"#495057",
			"#343a40",
			"#212529",
		],
		gray: "#495057",
		red: "#e03131",
		reds: ["#ffe3e3", "#ffa8a8", "#ff6b6b", "#f03e3e", "#c92a2a"],
		green: "#099268",
		greens: ["#c3fae8", "#63e6be", "#20c997", "#0ca678", "#087f5b"],
		yellow: "#f08c00",
		yellows: ["#fff3bf", "#ffe066", "#fcc419", "#f59f00", "#e67700"],
		secondary: "#30c",
		secondarys: ["#30c", "yellow"],
		highlight: "hsla(205, 100%, 40%, 0.125)",
	},
	fonts: {
		body: "Space Mono, sans-serif",
		heading: "Nunito Sans, sans-serif",
		monospace: "Space Mono",
	},
	fontSizes: {
		overline: 12,
		caption: 14,
		body: 16,
		subtitle: 18,
		h6: 20,
		h5: 24,
		h4: 34,
		h3: 48,
		h2: 60,
		h1: 96,
	},
	fontWeights: {
		heading: 900,
		light: 300,
		normal: 400,
		medium: 500,
		bold: 700,
		bolder: 900,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.25,
	},
	space: [0, 2, 4, 8, 16, 32, 64, 128, 256, 512],
	sizes: [0, 2, 4, 8, 16, 32, 64, 128, 256, 512],
	radii: {
		default: 4,
		circle: 99999,
	},
	shadows: {
		card:
			"0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
	},
	text: {
		heading: {
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
		},
		display: {
			fontFamily: "heading",
			fontWeight: "normal",
			lineHeight: "heading",
			fontSize: [5, 6, 7],
		},
		caps: {
			textTransform: "uppercase",
			letterSpacing: "0.1em",
		},
	},
}
